import React, { Component } from 'react';
import G6 from '@antv/g6'
import withContext from '../../../common/context/withContext'
import {View, GetGraphData, LogicLinks} from './config'
import Menu from './menu'
import Method from '../../../global/method';

const Minimap = require('@antv/g6/build/grid')
const minimap = new Minimap()

const Util = G6.Util;

class Main extends Component {
  async componentDidMount() {
    this.linksKind = 0
    this.props.onRefGraph(this)
    this.init()
    await this.draw()
    this.drawLogicLinks()
    this.props.updateButtonStatus()
  }
  componentWillUnmount() {}
  draw = () => {
    if (this.props.topology) {
      const { clientWidth, clientHeight } = document.getElementById('mountNode');
      this.drawGraph({w: clientWidth, h: clientHeight}, this.props.topology)
    }
  }
  state = {
    addNode: this.props.addNode,
    // removeItemId: this.props.removeItemId,
    settingId: this.props.settingId,
    saveEl: this.props.saveEl,
  }
  getData = () => {
    if (!this.props.hub) {
      return {
        data: null,
        error: true,
      }
    }
    const { clientWidth, clientHeight } = document.getElementById('mountNode');
    const hubModel = this.graph.findById(this.props.hub)
    const Edges = hubModel.getEdges()
    let error = false
    let data = {
      nodes: [],
      links: [],
    }
    for (let index = 0; index < Edges.length; index++) {
      let edge = Edges[index];
      let Edge = edge.getModel()
      let Node = this.graph.findById(Edge.target).getModel()
      // add hub
      if (index === 0) {
        const Hub = this.graph.findById(Edge.source).getModel()
        let hubData = Hub.setting.data || {} 
        hubData.kind = 1
        hubData.edge_sn = Hub.id
        if (!hubData.coordinate) {
          hubData.coordinate = [Hub.x, Hub.y < clientHeight / 2 ? 0 : 1]
        }
        hubData.local_subnets = hubData.local_subnets || [{}]
        hubData.local_subnets[0].addr = Hub.setting.local_subnets.addr
        hubData.sg_id = Hub.setting.sg_id
        if (this.props.basic.mode === 0) {
          hubData.local_subnets[0].addr2 = Hub.setting.local_subnets.addr2
          hubData.dns_server = Hub.setting.dns_server
        }
        if (this.props.basic.mode === 1) {
          hubData.bypass_deploy = Hub.setting.bypass_deploy
          hubData.extra_routes = Hub.setting.extra_routes
          // hubData.tunnel_sources = Hub.setting.tunnel_sources || [new Method.IpInfo(Hub.setting.local_subnets.addr).toGetNetIp()]
        }
        data.nodes.push(hubData)
      }
      // add link
      let linkData = Edge.setting.data || {} 
      // linkData.kind = Edge.setting.kind
      linkData.kind = this.linksKind
      linkData.client_sn = Edge.target
      linkData.server_sn = Edge.source
      linkData.tcp_optimize = Edge.setting.tcp_optimize
      data.links.push(linkData)
      // add node
      let nodeData = Node.setting.data || {} 
      nodeData.kind = 0
      nodeData.edge_sn = Node.id
      if (!nodeData.coordinate) {
        nodeData.coordinate = [Node.x, Node.y <= clientHeight / 2 ? 0 : 1]
      }
      if (!Node.setting.local_subnets.addr
          || (this.props.basic.mode === 0
          && (!Node.setting.local_subnets.addr2 || !Node.setting.dns_server))
          || (this.props.basic.mode === 0
          && !Node.setting.sg_id)
      ) {
        error = true
      } else {
        nodeData.local_subnets = nodeData.local_subnets || [{}]
        nodeData.local_subnets[0].addr = Node.setting.local_subnets.addr
        nodeData.sg_id = Node.setting.sg_id
        if (this.props.basic.mode === 0) {
          nodeData.local_subnets[0].addr2 = Node.setting.local_subnets.addr2
          nodeData.dns_server = Node.setting.dns_server
        }
        if (this.props.basic.mode === 1) {
          nodeData.bypass_deploy = Node.setting.bypass_deploy
          nodeData.extra_routes = Node.setting.extra_routes
          nodeData.snat = Node.setting.snat
          // nodeData.tunnel_sources = Node.setting.tunnel_sources || [new Method.IpInfo(Node.setting.local_subnets.addr).toGetNetIp()]
        }
      }
      data.nodes.push(nodeData)
      if (this.props.basic.mode === 0) {
        data.logic_links = this.props.logicLinks
      }
    }
    let Data = {
      data: data,
      error: error,
    }
    return Data
  }
  getParams = () => {
    const data = this.getData()
    if (data.error) {
      return null
    } else {
      return data.data
    }
  }
  drawGraph = (size, data) => {
    if  (!size) {
      const { clientWidth, clientHeight } = document.getElementById('mountNode');
      size = {w: clientWidth, h: clientHeight}
    }
    if (!data) {
      data = this.getData().data
    }
    let graphData = GetGraphData(size, data)
    let nodes = graphData.nodes.map((node, index) => {
      node.label = this.props.edges.find(edge => {
        return node.id === edge.sn
      }).name
      if (Object.keys(node.setting).length !== 0 && node.kind === 0) {
        node = {...node, ...View.spoke.edit, ...View.spoke.state[1]}
      }
      if (Object.keys(node.setting).length !== 0  && node.kind === 1) {
        this.props.setValue({hub: node.id})
        node = {...node, ...View.hub.edit, ...View.hub.state[1]}
      }
      return node
    })
    graphData.nodes = nodes
    this.linksKind = data.links[0].kind
    this.graph.changeData(graphData)
  }
  drawLogicLinks = () => {
    return
    if (!this.props.hub) {
      return
    }
    const edges = this.graph.getEdges()
    const logicLinks = JSON.parse(JSON.stringify(this.props.logicLinks || []))
    const arrays = edges.length > logicLinks.length ? edges : logicLinks
    const logicLinksConfig = {...View.line.logic}
    edges.map((edge, index) => {
      edge = edge.getModel()
      if (edge.type === 'logic_link') {
        this.removeItem('edge', edge.id)
      }
    })
    arrays.map((ele, index) => {
      let logicLink = logicLinks[index]
      if (!!logicLink) {
        let data = {
          id: logicLink.id || String(Date.now() + index),
          type: 'logic_link',
          source: logicLink.node1,
          target: logicLink.node2,
          shape: 'hvh',
          ...logicLinksConfig,
        }
        this.addItem('edge', data)
      }
    })
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.addNode !== prevState.addNode) {
      return {addNode: nextProps.addNode}
    }
    if (nextProps.settingId !== prevState.settingId) {
      return {settingId: nextProps.settingId}
    }
    if (nextProps.saveEl !== prevState.saveEl) {
      return {saveEl: nextProps.saveEl}
    }
    return null
  }
  componentDidUpdate(prevProps, prevState){
    if (this.state.addNode.id === null) {
      // if (this.graph.findById('drage-background')) {
      //   this.removeItem('background', 'drage-background')
      // }
      // this.graph.setMode('default') 
    } else {
      if (this.graph.getCurrentMode() !== 'addNode') {
        this.graph.setMode('addNode') 
      }
    }
    if (this.state.settingId !== null) {
      const item = this.graph.findById(this.state.settingId.id)
      const itemModel = item.getModel()
      let setting = {
        id: this.state.settingId.id,
        type: this.state.settingId.type,
        title: this.state.settingId.type === 'node' ? itemModel.label : item._cfg.source._cfg.model.label + ' - ' + item._cfg.target._cfg.model.label,
        setting: itemModel.setting,
      }
      if (setting.type === 'node') {
        if (this.props.basic.mode === 0) {
          const spokes = this.getNodes('spoke')
          setting.logicLinksData = {
            selectedArray: setting.setting.kind === 0 ? LogicLinks.selectedArray(this.props.logicLinks, this.state.settingId.id) : [],
            spokeList: spokes,
          }
        }
        if (this.props.basic.mode === 1) {
        }
      }
      if (setting.type === 'edge') {
        setting.setting.kind = this.linksKind
      }
      this.props.setValue({
        settingId: null,
        setting: setting,
      })
    }
    if (this.state.saveEl) {
      const values = this.props.setting
      const item = this.graph.findById(this.props.setting.id)
      let style = values.setting.kind === 1 ? {...View.hub.edit, ...View.hub.state[1]} : {...View.spoke.edit, ...View.spoke.state[1]}
      let logicLinks = {}
      let updateType = {}
      const id = values.id
      if (values.type === 'node') {
        let tcpOptimize = {
          tcp_optimize: {
            enable: true,
          },
        }
        if (this.props.basic.mode === 0) {
          const links = this.props.logicLinks
          const selectedArray = values.logicLinksData.selectedArray
          if (values.setting.kind === 1) {
            logicLinks = {logicLinks: LogicLinks.newLinks('delete', links, id)}
          } else {
            logicLinks = {logicLinks: LogicLinks.newLinks('edit', links, id, selectedArray)}
          }
          updateType = {type: 'logicLinks', id: id}
        }
        this.graph.update(item, {
          kind: values.setting.kind,
          setting: values.setting,
          ...style 
        })
        if (values.setting.kind === 1 ) {
          const Nodes = this.graph.getNodes()
          Nodes.map((Node, index) => {
            if (Node._cfg.edges.length === 0 && (!Node._cfg.model.setting || Node._cfg.model.setting.kind !== 1)) {
              this.addItem('edge', {
                id: String(Date.now() + index),
                source: this.props.hub,
                target: Node._cfg.id,
                setting: {
                  kind: 0,
                  ...tcpOptimize,
                },
              })   
            }
          })
        } else {
          if (values.id === this.props.hub) {
            const Edges = this.graph.getEdges()
            let ids = []
            Edges.map(Edge => {
              ids.push(Edge._cfg.id)
            })
            ids.map(id => {
              this.removeItem('edge', id.toString())
            })
            this.props.setValue({hub: null})
          }
        }
      }
      if (values.type === 'edge') {
        this.graph.update(item, {
          kind: values.setting.kind,
          setting: values.setting,
        })
        this.linksKind = values.setting.kind
      }
      this.props.setValue({
        saveEl: null,
        setting: null,
        ...logicLinks,
      },() => {
        this.props.updateButtonStatus(updateType)
      })
    }
    if (prevProps.logicLinks !== this.props.logicLinks) {
      this.drawLogicLinks()
    }
    return true
  }
  init() {
    const { clientWidth, clientHeight } = document.getElementById('mountNode');
    const that = this
    G6.registerBehavior('contextmenu-menu', {
      getEvents() {
        return {
          'node:contextmenu': 'onContextMenu',
          'canvas:click': 'Click',
          'node:click': 'Click',
          'edge:click': 'Click',
        }
      },
      onContextMenu(e) {
      },
      Click(e) {
        const graph = this.graph
      }
    })
    G6.registerBehavior('drag-add-node', {
      getEvents() {
        return {
          'canvas:mouseenter': 'onInOfRange',
          'canvas:mousemove': 'onMouseMove',
          'canvas:mouseleave': 'onOutOfRange',
          'node:mouseup': 'onMouseUp',
          'canvas:mouseup': 'onCanvasMouseUp',
        };
      },
      onInOfRange(ev) {
        if (!that.props.addNode.id) {
          return
        }
        const graph = this.graph;
        const item = graph.findById('drage-background')
        const fn = () => {that.removeItem('background', 'drage-background')}
        if (item) {
          fn()
        }
        that.addItem('background', {
          x: ev.x,
          y: ev.y,
          id: 'drage-background',
          ...View.delegateNode,
        });
      },
      onMouseMove(e) {
        const  item = that.graph.findById('drage-background')
        that.graph.update(item, {
          x: e.x,
          y: e.y,
        });
      },
      onMouseUp(ev) {
        that.removeItem('background', 'drage-background')
        if (that.props.addNode.id) {
          const data = that.props.addNode.data
          let setting = {
            kind: 0,
          }
          if (data.local_subnets) {
            setting.local_subnets = {
              addr: data.local_subnets[0].addr,
              addr2: data.local_subnets[0].addr2,
            }          
          } else {
            setting.local_subnets = {
              addr: '',
              addr2: '',
            }          
          }
          if (data.sg_id) {
            setting.sg_id = data.sg_id
          }
          if (data.dns_server) {
            setting.dns_server = data.dns_server
          }
          if (that.props.basic.mode === 1) {
            if (data.hasOwnProperty('bypass_deploy')) {
              setting.bypass_deploy = data.bypass_deploy
            }
            if (data.hasOwnProperty('extra_routes')) {
              setting.extra_routes = data.extra_routes || []
            }
            if (data.hasOwnProperty('snat')) {
              setting.snat = data.snat
            }
            // if (data.hasOwnProperty('tunnel_sources')) {
            //   setting.tunnel_sources = data.tunnel_sources
            // }
          }
          let settingIs = setting.local_subnets.addr && (setting.sg_id && setting.dns_server && setting.local_subnets.addr2 || that.props.basic.mode === 1)
          let style = settingIs ? {...View.spoke.edit, ...View.spoke.state[1]} : {...View.spoke.edit, ...View.spoke.state[0]} 
          that.addItem('node', {
            x: ev.x,
            y: ev.y,
            id: that.props.addNode.sn, 
            label: that.props.addNode.name,
            setting: setting,
            data: data,
            ...style
          })
          if (that.props.hub) {
            let tcpOptimize = {}
            tcpOptimize.tcp_optimize = {
              enable: true,
            }
            that.addItem('edge', {
              id: String(Date.now()),
              source: that.props.hub,
              target: that.props.addNode.sn,
              setting: {
                kind: 0,
                ...tcpOptimize,
              },
            })
          }
          setTimeout(()=>{
            this.graph.setMode('default') 
          }, 200)
        }
      },
      onOutOfRange(e) {
        that.removeItem('background', 'drage-background')
      },
      onCanvasMouseUp(e) {
      }
    });
    G6.registerEdge('hvh', {
      getControlPoints(cfg) {
        const controlPoints = []; // 指定controlPoints
        const { clientWidth, clientHeight } = document.getElementById('mountNode');
        let { startPoint, endPoint } = cfg;
        if (!startPoint.id) {
          startPoint = cfg.sourceNode.getModel()
          endPoint = cfg.targetNode.getModel()
        }
        const {x: x1, y: y1} = startPoint
        const {x: x2, y: y2} = endPoint
        const centerX = clientWidth / 2
        const centerY = clientHeight / 2
        const getY = (pointX, pointY) => {
          let y = pointY
          if (centerX === x1) {
            return null
          }
          y = (pointX - x1) / (centerX - x1) * (centerY - y1) + y1
          return Math.abs(y)
        }
        const level1 = () => {
          const max = 680
          const length = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
          return Math.floor(length / max * 10)
        }
        const level2 = () => {
          let attr = 1
          const y = getY(x2, y2)
          if (!y) {
            if (y1 <= centerY) {
              attr = x2 <= centerX ? 1: -1
            } else {
              attr = x2 <= centerX ? -1: 1
            }
            return attr
          } 
          if (x1 <= centerX) {
            attr = y2 >= y ? 1 : -1
          } else {
            attr = y2 >= y ? -1 : 1
          }
          return attr
        }
        const level = level1() * level2()
        const offset = level * 30; // 根据不同的level 计算不同的偏移
        const innerPoint = Util.getControlPoint(startPoint, endPoint, 0.5, offset);
        controlPoints.push(innerPoint);
        return controlPoints;
      },
    }, 'quadratic');
    this.graph = new G6.Graph({
      container: 'mountNode',
      width: clientWidth,
      height: clientHeight,
      plugins: [ minimap ],
      modes: {
        default: ['drag-node', 'click-select'],
        addNode: ['drag-add-node'],
        menu: [ 'contextmenu-menu', ],
      },
      defaultNode: {...View.spoke.edit},
      defaultEdge: {...View.line.edit},
    })
    const data = {
    };
    that.graph.data(data);
    that.graph.render();
    that.graph.setMode('defalut')
    that.graph.on('contextmenu', (ev) => {
      if (ev.item) {
        const data = ev.item.getModel()
        let menu = {}
        if (data.startPoint) {
          menu = {
            id: data.id,
            x: ev.x + 10,
            y: ev.y + 10,
            type: 'edge', 
          }
        } else {
          menu = {
            id: data.id,
            x: data.x + 20,
            y: data.y + 10,
            type: 'node', 
          }
        }
        that.props.setValue({menu: menu})
      }
    });
  }
  closeMenu = (e) => {
    if (this.props.menu) {
      this.props.setValue({menu: null})
    }
  }
  getNodes = () => {
    const nodes = this.graph.getNodes()
    let nodesArray = []
    nodes.map(e => {
      let model = e.getModel()
      if (model.setting.kind === 0) {
        nodesArray.push({
          id: model.id,
          name: model.label,
        })
      }
    })
    return nodesArray
  }
  removeItem = (type, id) => {
    id = String(id)
    if (type === 'node') {
      this.graph.removeItem(id)
      let params = {
        removeItemId: id,
      }
      if (id === this.props.hub) {
        params.hub = null
      } else {
        const links = this.props.logicLinks
        const newLinks = LogicLinks.newLinks('delete', links, id)
        params.logicLinks = newLinks
      }
      this.props.setValue(params, () => {
        this.props.updateButtonStatus()
      })
    }
    if (type === 'edge') {
      this.graph.removeItem(id)
    }
    if (type === 'background') {
      this.graph.removeItem('drage-background')
    }
  }
  addItem = (type, data) => {
    if (type === 'node') {
      this.graph.addItem('node', data)
      const links = JSON.parse(JSON.stringify(this.props.logicLinks))
      const spokes = this.getNodes('spoke')
      const newLinks = LogicLinks.newLinks('add', links, data.id, spokes)
      this.props.setValue({
        addItemId: data.id,
        logicLinks: newLinks,
      })
    }
    if (type === 'edge') {
      this.graph.addItem('edge', data)
      this.props.updateButtonStatus()
    }
    if (type === 'background') {
      this.graph.addItem('node', data)
    }
    // this.props.updateButtonStatus()
  }
  render () {
    return(
      <div 
        onContextMenu={(e)=>  {e.preventDefault(); return false;}}
        onClick={this.closeMenu}
        style={{
          position: 'absolute',
          left: 200,
          right: 0,
          top: 46,
          bottom: 0,
        }}
      >
        <div
          id="mountNode"
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        >
        </div>
        <Menu removeItem={this.removeItem}></Menu>
      </div>
    )
  }
}
export default withContext(Main);